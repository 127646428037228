
.starbutton{
    position: absolute;
    left: 50px;
    top: 350px;
    animation:my1 1s;
	-webkit-animation:my1 1s;
}
@keyframes my1
{
	from {opacity: 0;}
	to {opacity:1;}
}

@-webkit-keyframes my1 /* Safari and Chrome */
{
	from {opacity: 0;}
	to {opacity: 1;}
}
