.largebody {
    width: 1220px;
    margin: auto;
}
.Bread {
    margin-top: 30px;
}
/* 标题样式 */
.largetitle{
    width: 100%;
    height: 75px;
    margin-top: 100px;
    margin-bottom: 30px;
    text-align: center;
    border-bottom: 1px solid #D8D8D8;
}
.largetitle h1{
    font-size: 30px;
    font-family: Noto Sans S Chinese;
    font-weight:600;
}
.largetxt {
    width: 100%;
}
.largetxt p{
    text-indent:2em;
    font-size: 20px;
    color: #2D2D2D;
}
.largetxt img {
    width: 100%;
    height: 686px;
    margin-top: 30px;
}
