.largegmbody {
    h3 {
        font-size: 12px;
        font-family: Noto Sans S Chinese;
        font-weight: bold;
        line-height: 20px;
        color: #2D2D2D;
        opacity: 1;
    }
}   
 .separatedm {
        width: 100%;
        margin-top: 30px;
        height: 5px;
        background-color: #F5F5F5;
    }