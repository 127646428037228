.qqq {
        // 分页样式
        .swiper-pagination-bullet {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            transition:all 0.3s;
            -moz-transition:all 0.3s; /* Firefox 4 */
            -webkit-transition:all 0.3s; /* Safari and Chrome */
            -o-transition:all 0.3s; /* Opera */
        }
        .swiper-pagination-bullet.swiper-pagination-bullet-active {
            width: 9px;
            height: 5px;
            border-radius: 5px;
        }
}
.solutionswiper {
    width: 100%;
    height: 280px;
    // background-color: thistle;
    .solutionswiperimg {
        width: 100%;
        height: 180px;
        // background-color: violet;
        img {
        width: 90%;
        height: auto;
        display: block;
        -webkit-filter: brightness(0.50);
        filter: brightness(0.50);
        margin: auto;
    }
    // 文字部分样式
    }  
    .solutionswipertxt {
        text-align: center;
    h3 {
            font-size: 14px;
    }
    p {
            font-size: 12px;
        }
    }
}