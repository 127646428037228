.Square {
    position: relative;
    width: 1220px;
    height: 430px;
    margin-top: 40px;
    -webkit-box-shadow: #888888 0px 0px 15px;
    -moz-box-shadow: #888888 0px 0px 15px;
    box-shadow: #888888 0px 0px 15px;
}
.Squareimgsmall {
    position: absolute;
    top: 33px;
    left: 665px;
}
.Squareimg {
    float: left;
    width: 700px;
    height: 430px;
}
.Squareimg img{
    float: left;
    width: 700px;
    height: 430px;
}
.Squaretext {
    float: left;
    width: 520px;
    height: 430px;
    text-align: center;
}
.Squaretext h1{
    margin: auto;
    margin-top: 120px;
    font-family: Noto Sans S Chinese;
    font-size: 26px;
    font-weight: bold;
}
.Squaretext p{
    margin-top: 40px;
    color: #787878;
    font-size: 16px;
}
.showsbutton {
    margin-top: 120px;
    margin-left: 260px;
    background-color: #2468F2;
}