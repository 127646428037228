.largegmbody {
    width: 100%;
    max-width: 340px;
    margin: auto;
    .largegmtop {
    height: 60px;
    width: 100%;
    border: 1px solid white;
    // background-color: brown;
    img {
        width: 6px;
        margin-top: 0px;
    }
    }
    .backm {
        width: 40px;
        height: 20px;
        // margin-left: 20px;
        margin-top: 20px;
        // background-color: burlywood;
        span {
            width: 21px;
            font-size: 10px;
            font-family: Noto Sans S Chinese;
            font-weight: normal;
            line-height: 17px;
            color: #2D2D2D;
            margin-left: 10px;
            opacity: 1;
        }
    }
    h1 {
        width: 300px;
        font-size: 20px;
        font-family: Noto Sans S Chinese;
        font-weight: bold;
        line-height: 34px;
        color: #2D2D2D;
        opacity: 1;
    }
    h4 {
        display: block;
        width: 220px;
        height: 10px;
        font-size: 10px;
        margin-bottom: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        line-height: 17px;
        color: #2D2D2D;
        opacity: 1;
    }
    p {
        width: 335px;
        font-size: 12px;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        line-height: 20px;
        color: #2D2D2D;
        opacity: 1;
        text-indent: 2em;
    }
    img {
        width: 100%;
        margin-top: 20px;
    }
}
