.limit {
    float: left;
    width: 50%;
    height: 300px;
    background-color: tomato;
}
.solutionimg1{
    margin-top: 10px;
    width: 83px;
    height: 83px;

}
div .solutiontext{
    float: left;
    margin-right: 154px;
    margin-top: 20px;
    margin-bottom: 75px;
    width: 500px;
    height: 330px;
    text-align: justify;
    /* background-color: springgreen; */
}
.solutiontext h2{
    margin-top: 38px;
    margin-left: 40px;
    font-family: Noto Sans S Chinese;
    font-size: 20px;
    font-weight: 600;
    color: #2D2D2D;
}
.solutiontext p{
    margin-left: 40px;
    font-size: 14px;
    color: #2D2D2D;
}