div .starm {
    position: relative;
    width: 100%;
    height: 630px;
    background-color: #F5F5F5;
    margin-top: 30px;
    .starmtitle {
        text-align: center; 
        border: 1px solid #F5F5F5;
        h1 {
        font-size: 20px;
        margin-top: 40px;
        margin-bottom: 40px;
        }
    } 
    .swiper-slide {
    // width: 100%;
    height:480px;
    background-color: #F5F5F5;
    } 
    .swiper {
        width: 100%;
        height: 100%;
    }
      
    .swiper-slide {
        // text-align: center;
        font-size: 18px;
        // background: #fff;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    // 分页样式
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transition:all 0.3s;
        -moz-transition:all 0.3s; /* Firefox 4 */
        -webkit-transition:all 0.3s; /* Safari and Chrome */
        -o-transition:all 0.3s; /* Opera */
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        width: 20px;
        border-radius: 5px;
    }
    // 背景颜色替换
    .starmcolor {
                width: 100%;
                height: 428px;
                background-color:  #FFFFFF; 
                border-radius: 6px;
                box-shadow: 0px 0px 5px #888888;
    }

    .swiper-container {
        height: 80%;
    }
    .starmbox {
        position: relative;
        height: 430px;
        // 图片样式
        .starmboximg {
        width: 100%;
        height: 178px;
        img {
            width: 100%;
        }
        } 
        // 字体样式
        h1 {
            font-size: 14px;
            margin-top: 15px;
            margin-left: 10px;
        }
        // 横向的方块列表设置
        ul {
            width: 100%;
            height: 20px;
            // background-color: hotpink;
            margin-bottom: 25px;
            list-style: none;
            li {
                float: left;
                width: 55px;
                height: 20px;
                margin-right: 10px;
                color: #4C7BE9;
                text-align: center;
                padding-top: 3px;
                font-size: 10px;
                background-color: #F2F3F7;
                border-radius: 4px;
            }
        }
        p {
            margin-left: 10px;
            font-size: 10px;
            color: #787878;
        } 
        // 按钮的样式
        .starmbutton {
        position: absolute;
        top: 90%;
        left: 60%;
        background-color: #2468F2;
        span {
            font-size: 12px;
            color: white;
        } 
    }
    }
}
@keyframes my1
{
	from {opacity: 0;}
	to {opacity:1;}
}

@-webkit-keyframes my1 /* Safari and Chrome */
{
	from {opacity: 0;}
	to {opacity: 1;}
}