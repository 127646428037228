div .FunctionalMboxbody {
    width: 100%;
    height: 450px;
    // background-color: brown;
}
// 带蓝色背景的样式
div .FunctionalMbox {
    float: left;
    width: 45%;
    height: 200px;
    border-radius: 6px;
    background-image: url('../../../assets/bluebig.png');
    background-size: 100% 200px;
    animation:myfirst 1s;
    -webkit-animation:myfirst 1s;
    h1 {
        display: block;
        margin-left: 12px;
        color:white;
        font-size: 14px;

    }
    p {
       font-size: 10px;
       color: white; 
    }
}

// 改变后的样式
div .FunctionalMboxg {
    float: left;
    width: 45%;
    height: 200px;
    border-radius: 6px;
    background-color: #F2F7FF;
    overflow: hidden;
    // animation:myfirst 1s;
    // -webkit-animation:myfirst 1s;
    // transition:all 1s;
    // -moz-transition:all 1s; /* Firefox 4 */
    // -webkit-transition:all 1s; /* Safari and Chrome */
    // -o-transition:all 1s; /* Opera */
    h1 {
        display: block;
        margin-left: 12px;
        color: #2D2D2D;
        font-size: 14px;
    }
    p {
        font-size: 10px;
        color: #2D2D2D;
    }
}   
 // 内部文字的样式
    div .FunctionalMboxtxt {
    width: 100%;
    height: 100px;
    // background-color: yellowgreen;
    margin-top: 20px;
    }
    // 按钮和img位置方块
    .FunctionalMboximg {
        width: 100%;
        height: 80px;
        // background-color: tomato;
    }
    // 按钮样式
    .productmbody .FunctionalMbutton {
        position: relative;
        left: 6%;
        top: 30px;
        width: 100px;
        height: 20px;
        background-color: #2D2D2D;
        border-radius: 2px;
        h1 {
            display: block;
            margin-left: 6px;
            font-size: 8px;
            line-height: 20px;
        }
        img {
            position: absolute;
            width: 11.92px;
            height: 2.84px;
            left: 70px;
            top: 8px;
        }
    }
    .productmbody .ltt {
        display: block;
        margin-left: 20%;
        margin-top: -15%;
        width: 90%;
        height: auto;
    }
    @keyframes myfirst
    {
        from {opacity: 0;}
        to {opacity:1;}
    }
    
    @-webkit-keyframes myfirst /* Safari and Chrome */
    {
        from {opacity: 0;}
        to {opacity: 1;}
    }