div .DynamicM {
    width: 100%;
    height: 400px;
    margin-top: 20px;
    max-width: 340px;
    // background-color: skyblue;
    // 设置一个块让标题居中
    .DynamicMtitle {
        width: 100%;
        height: 25px;
        margin-bottom: 10px;
        text-align: center;
        // background-color: snow;
    }
    h1 {
        display: inline-block;
        font-size: 20px;
    }
    .DynamicMbox {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        // background-color: springgreen;
        img {
            float: left;
            width: 45%;
            height: 100px;
        }
        .DynamicMtxt {
            float: left;
            margin-left: 10px;
            width: 50%;
            height: 100px;
            // background-color: tomato;
            h2 {
                font-size: 12px;
                margin-top: 6px;
                margin-bottom: -5px;
            }
            span {
                font-size: 8px;
                color: #787878;
            }
            p {
                margin-top: 10px;
                font-size: 10px;
                color: #787878;
            }
        }
    }
}