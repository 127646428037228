.alsa {
    width: 100%;
    height: 100px;
    text-align: center;
}
.alsa h2{
    font-size: 46px;
    color:#777777 ;
}
.scenarioss1{
    position: absolute;
    width: 580px;
    margin-top: 20px;
    height: 300px;
    background-image: url(@assets/汽车.png);
    background-size: cover;
    background-position: center;
    /* -webkit-filter: brightness(0.50);
    filter: brightness(0.50); */
}
.scenarioss2{
    position: absolute;
    margin-top: 20px;
    left: 640px;
    width: 580px;
    height: 300px;
    background-image: url(@assets/高校.png);
    background-size: cover;
    background-position: center;
    /* -webkit-filter: brightness(0.50);
    filter: brightness(0.50); */
}
.scenarioss3{
    position: absolute;
    top: 480px;
    width: 580px;
    height: 300px;
    background-image: url(@assets/安防.png);
    background-size: cover;
    background-position: center;
    /* -webkit-filter: brightness(0.50);
    filter: brightness(0.50); */
}
.scenarioss4{
    position: absolute;
    left: 640px;
    top: 480px;
    width: 580px;
    height: 300px;
    background-image: url(@assets/景区.png);
    background-size: cover;
    background-position: center;
    /* -webkit-filter: brightness(0.50);
    filter: brightness(0.50); */
}
/* 蓝色背景 */
.scenarioss11{
    position: absolute;
    width: 580px;
    float: left;
    margin-top: 20px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0;
}
.scenarioss22{
    position: absolute;
    width: 580px;
    left: 640px;
    float: left;
    margin-top: 20px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0;
}
.scenarioss33{
    position: absolute;
    width: 580px;
    top: 480px;
    float: left;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0;
}
.scenarioss44{
    position: absolute;
    width: 580px;
    top: 480px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0;
    left: 640px;
}
.scenarioss11:hover{
    position: absolute;
    width: 580px;
    float: left;
    margin-top: 20px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0.5;
}
.scenarioss22:hover{
    position: absolute;
    width: 580px;
    left: 640px;
    float: left;
    margin-top: 20px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0.5;
}
.scenarioss33:hover{
    position: absolute;
    width: 580px;
    top: 480px;
    float: left;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0.5;
}
.scenarioss44:hover{
    position: absolute;
    width: 580px;
    top: 480px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0.5;
    left: 640px;
}
/* 背景下个小标签的样式 */
.wisdoms{
    position: absolute;
    top: 200px;
    left: 19%;
    font-size: 30px;
    color: white;
}
.Collegess {
    position: absolute;
    top: 200px;
    left: 71%;
    font-size: 30px;
    color: white;
}
.securitys {
    position: absolute;
    top: 580px;
    left: 19%;
    font-size: 30px;
    color: white;
}
.spots {
    position: absolute;
    top: 580px;
    left: 71%;
    font-size: 30px;
    color: white;
}
.wisdomps{
    position: absolute;
    top: 270px;
    left: 6.5%;
    width: 35%;
    font-size: 16px;
    color: white;
    text-align: center;
}
.Collegesps {
    position: absolute;
    top: 270px;
    left: 59%;
    width: 35%;
    font-size: 16px;
    color: white;
    text-align: center;
}
.securityps {
    position: absolute;
    top: 650px;
    left: 6.5%;
    width: 35%;
    font-size: 16px;
    color: white;
    text-align: center;
}
.spotps {
    position: absolute;
    top: 650px;
    left: 59%;
    width: 35%;
    font-size: 16px;
    color: white;
    text-align: center;
}