div .HeaderM {
    width: 100%;
    // height: 50px;
    img {
        position: absolute;
        display: inline-block;
        width: 92.43px;
        height: 12.61px;
        top: 23px;
        left: 20px;
        z-index:2;
    }
    .caidan {
        width: 20px;
        height: 15.79px;
        left: 219px;
        top: 21px;
        animation:my1 2s;
        -webkit-animation:my1 2s;
    }
    .chahao {
        width: 19.3px;
        height: 19.68px;
        left: 219px;
        top: 21px;
        animation:my1 2s;
        -webkit-animation:my1 2s;
    }
    @keyframes my1
    {
	from {opacity: 0;}
	to {opacity:1;}
    }
    @-webkit-keyframes my1 /* Safari and Chrome */
    {
	from {opacity: 0;}
	to {opacity: 1;}
    }
    // 设置无体积
    .am-accordion .am-accordion-item .am-accordion-content {
        position: absolute;
        width: 100%;
        height: 900px;
        z-index: 5;
    }
    // 设置手风琴标题
    .am-accordion .am-accordion-item .am-accordion-header {
        background-color: #2468F2;
        color: #FFFFFF;
        font-size: 12px;
        padding-left: 20px;
    }
    // 设置图标
    .am-accordion .am-accordion-item .am-accordion-header i {
        width: 18px;
        height: 18px;
        top: 14px;
    }
    // 消除下方灰线
    .am-accordion .am-accordion-item .am-accordion-header::after {
        height: 0;
    }
    // 消除上方灰线
    .am-accordion::before {
        height: 0;
    }
    // 下拉内容样式
    .am-list-item .am-list-line .am-list-content {
        color: #2468F2;
        font-size: 16px;
        text-align: center;
    }
    .am-list-item {
        min-height: 33px;
        margin-bottom: 30px;
        font-weight: bold;
    }
    // 下拉灰线去除
    .am-list-body div:not(:last-child) .am-list-line::after {
        height: 0;
    }
    .am-list-item:not(:last-child) .am-list-line::after {
        height: 0;
    }
    .am-list-body::after {
        height: 0;
    }
    .am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box::after {
        height: 0;
    }
    // 手风琴头部设置
    .am-accordion .am-accordion-item .am-accordion-header {
        // width: 100%;
        height: 60px;
    }
    // 头部图标变换
    .am-accordion .am-accordion-item .am-accordion-header i{
        background-image: url('../../../assets/菜单.png');
        transform: rotate(0deg);
        width: 19px;
        height: 19px;
        right: 24px;
        top: 20px;
    }
    .am-accordion .am-accordion-item .am-accordion-header[aria-expanded~="true"] i {
        transform: rotate(90deg);
        background-image: url('../../../assets/叉号.png');
    }
}