.please {
    width: 100%;
    height: 700px;
    text-align: center;
}
.please h1{
    margin-top: 220px;
    font-size: 50px;
    font-weight: bold;
}
.plicon {
    font-size: 200px;
}