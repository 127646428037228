    // 顶部背景
    .aboutmback {
        position: relative;
        width: 100%;
        height: 220px;
        background-image: url('../../assets/关于我们banner.png');
        background-size: auto 220px;
        background-position: center;
        -webkit-filter: brightness(0.50);
        filter: brightness(0.50);
    } 
    // 背景图片的标题样式
    .aboutmbacktitle{
            position: absolute;
            font-size: 28px;
            color: #FFFFFF;
            top: 120px;
            left: 20px;
    }
    // 头部字体设置
    .aboutmbackp {
        position: absolute;
        top: 168px;
        left: 21px;
        font-size: 14px;
        color: white;
    }
    .aboutmbody {
        width: 100%;
        margin: auto;
        max-width: 340px;
        h2 {
        display: block;
        font-size: 14px;
        margin-bottom: 20px;
        width: 308px;
        color: #2D2D2D;
        border-bottom: 1px solid #2D2D2D;
        }
        // 介绍文字
        .aboutmbigp {
            display: block;
            margin: auto;
            width: 95%;
            font-size: 12px;
            color: #787878;
            text-align: justify; 
        }
        >img {
            width: 120%;
            margin-left: -10%;
            height: 142px;
            background-position: center;
        }
        // 联系我们
        .usm {
            margin-top: 20px;
            width: 100%;
            height: 60px;
            // background-color: skyblue;
            img {
                display: block;
                float: left;   
            }
            h3 {
                font-size: 12px;
                line-height: 26px;
            }
            .usmh3 {
                float: left;
                width: 88%;
                margin-left: 10px;
                height: 60px;
                // background-color: brown;
            }
        }
    }