.Solutions{
    position: relative;
}
div .visualization{
    width: 100%;
    height: 480px;
    background-image: url(@assets/三维数据可视化banner.png);
    background-size: 100% 480px;
}
/* div .visualizationup{
    position: absolute;
    top: 60px;
    width: 100%;
    height: 480px;
    background-color: black;
    opacity: 0.5;
} */
.visualization {
    width: 100%;
    height: 400px;
}
.Solution h1{
    position: absolute;
    top: 182px;
    left: 20%;
    color: white;
    font-size: 50px;
}
.Solutionp{
    position: absolute;
    top: 280px;
    left:20%;
    color: white;
    font-size: 20px;
}


/* 技术优势的样式 */

.advantages1{
    float: left;
    width: 1400px;
    margin-top: 100px;
    margin-left: 5%;
    height: 800px;
    /* background-color: teal; */
}
.applicationlist{
    float: left;
    margin-top: 100px;
    margin-right: 50px;
    width: 20%;
    height: 400px;
}
.solutioncase{
    float: left;
    margin-top:100px;
    width: 75%;
    height: 400px;
    /* background-color: gainsboro; */
}
/* 相关案例最外层 */
div .Application1{
    width: 70%;
    height: 600px;
    margin: auto;
    margin-top: 100px;
}


@import '~antd/dist/antd.css';
.Applicationglistul ul{
    list-style: none;
    font-size: 18px;
    margin-left: -40px;
}
.Applicationglistullihover{
    width: 100%;
    height: 60px;
    border-left:  3px solid #959595;
}   

.Applicationglistulli{
    width: 100%;
    height: 60px;
    border-left:  3px solid #4C7BE9;

}

.Applicationglistul p:hover{
    position: relative;
    top: 15px;
    left: 15px;
    color: #4C7BE9;
}   
.licss{
    position: relative;
    top: 15px;
    left: 15px;
    color: #959595;
    cursor: pointer;
}
.licss:hover{
    position: relative;
    top: 15px;
    left: 15px;
    color: #4C7BE9;
    cursor: pointer;
}
.licssg{
    position: relative;
    top: 15px;
    left: 15px;
    color: #4C7BE9;
    cursor: pointer;
}