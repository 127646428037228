div .other {
    float: left;
    margin-right: 5%;
    width: 30%;
    overflow: hidden;
    height: 500px;
}
.other img{
    width: 100%;
}
.other h2{
    margin-top: 15px;
    margin-left: 20px;
    font-weight: bold;
}
.other p {
    margin-left: 20px;
    font-size: 12px;
}
.other span{
    margin-left: 20px;
    color: #959595;
}
.otherimg {
    margin-left: -25%;
    width: 150%;
    height: 244px;
    overflow: hidden;
}
.otherimg img {
    display: block;
    margin: auto;
    width: 67%;
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.otherimg img:hover {
    width: 80%;
}