@import '~antd/dist/antd.css';
.solutioncasetext{
    position: relative;
    float: left;
    width: 40%;
    height: 400px;
    background-color: #224188;
}
.solutioncasetextp{
    float: left;
    margin-top: 80px;
    margin-left: 15%;
    width: 70%;
    text-align: justify;
}
.solutioncasetext p{
    color: white;
    font-size: 13px;
}
.solutionbutton {
    position: absolute;
    left: 15%;
    top: 270px;
    width: 100px;
}
.solutionbuttonfalse {
    margin-left: 10%;
    margin-top: 30px;
    width: 100px;
}
.solutionimgdivs { 
    width: 540px;
    height: 400px;
    overflow: hidden;
}
.solutionimgdiv img{ 
    /* width: 100%; */
    height: 400px;
}
.solutionimgdiv { 
    width: 540px;
    height: 400px;
    overflow: hidden;
}
.solutionimg {
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
}
.solutionimg:hover {
    margin: auto;
    transform: scale(1.15);
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
}