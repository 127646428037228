.abouttopmao {
    position: absolute;
    top: -10px;
    left:50%;
    width: 10px;
    height: 10px;
    background-color: sandybrown;
}
.aboutheader{
    position: relative;
    height: 480px;
    width: 100%;
}
.aboutimgs{
    position: relative;
    width: 100%;
    height: 480px;
    background-image: url('@assets/关于我们banner.png');
    /* -webkit-filter: brightness(0.50);
    filter: brightness(0.50); */
}
.aboutheader h1{
    position: absolute;
    top: 122px;
    left: 20%;
    color: white;
    font-size: 50px;
}
.aboutheader p{
    position: absolute;
    top: 220px;
    left:20%;
    color: white;
    font-size: 20px;
}
.aboutbody{
    position: relative;
    width: 1200px;
    margin: auto;
    /* height: 1500px; */
    /* background-color: red; */
}
/* 小标题的样式 */
.headings{
    margin-top: 60px;
    margin-bottom: 50px;
    width: 100%;
    height: 40px;
    /* background-color: sienna; */
    border-left: 3px solid #4C7BE9;
}
.headings h2{
    margin-left: 20px;
    font-size: 30px;
    font-weight: bold
}
/* 公司简介css */
.Companyprofile {
    width: 100%;
    height: 400px;
    /* background-color: rosybrown; */
}
.Companyprofileleft{
    float: left;
    width: 40%;
    height: 300px;
    margin-top: 50px;
    /* background-color: skyblue; */
}
.Companyprofilelefth2 {
    width: 92%;
    height:35px;
    border-bottom: 2px solid #707070;
}
.Companyprofileright {
    float: left;
    margin-top: 50px;
    margin-left: 10%;
    width: 50%;
    height: 300px;
    /* background-color: tan; */
}
.Companyprofileright p{
    font-size: 15px;
    color: #959595;
    text-align: justify;
}
/* 企业文化样式 */
.enterpriseculture{
    position: relative;
    width: 100%;
    height: 650px;
    margin-bottom: 200px;
    /* background-color:sandybrown; */
}
.enterpriseculturebody {
    margin-top: 100px;
    width: 100%;
    height: 500px;
    background-color: #F8F9FA;
}
.enterprisecultureright {
    position: absolute;
    left: 40%;
    top:200px;
    margin-left: 10%;
    width: 550px;
    height: 400px;
    /* background-color: springgreen; */
}
/* 合作企业样式 */
.cooperativelist {
    margin-top: 100px;
    margin-bottom: 50px;
    width: 100%;
    /* background-color: skyblue; */
}
/* 企业荣誉 */
.honor {
    position: absolute;
    margin: auto;
    width: 1200px;
    height: 500px;
    /* background-color: crimson; */
}
.honor img{
    margin-top: 50px;
    margin-left: 9%;
}
.Elegant {
    margin: auto;
    width: 1220px;
    height: 900px;
    margin-top: 100px;
    /* background-color: burlywood; */
    overflow: hidden;
}
.Elegantimg {
    width: 1260px;
    height: 700px;
    margin-top: -20px;
    /* background-color: coral; */
}
.Amaodian {
    margin: auto;
    margin-bottom: 100px;
    height: 50px;
    width: 120px;
}
.us {
    margin: auto;
    height: 600px;
    width:1200px;
}
.us h3{
    margin-top: 70px;
    font-size: 26px;
    font-weight: bold
}
.us p{
    color: #4C7BE9;
    font-size: 16px;
}
.honorimg {
    width: 100%;
}