.shuffling {
    width: 100%;  
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background-color: '';
        border: 2px solid #FFFFFF;
        opacity: 0.4;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
        background-color: white;
        opacity: 1;
    }
}