.pdat {
    width: 100%;
    height: 700px;
    margin-top: 100px;
}
.pdattitle {
    width: 100%;
    height: 55px;
    text-align: center;
}
.pdattitle h2{
    font-size: 46px;
    color: #777777;
}
.padtbody {
    position: relative;
    width: 100%;
}
/* 大一点的方块 */
.bluebig {
    float: left;
    width: 340px;
    height: 550px;
    margin-top: 68px;
    margin-right: 20px;
    color: #FFFFFF;
    background-image: url('@assets/bluebig.png');
    transition:all 1s;
    -moz-transition:all 1s; /* Firefox 4 */
    -webkit-transition:all 1s; /* Safari and Chrome */
    -o-transition:all 1s; /* Opera */
}
.bluebig h3 {
    display: block;
    margin-top: 40px;
    margin-left: 30px;
    margin-bottom: 45px;
    color: #FFFFFF;
    font-size: 24px;
}
.bluebig p {
    font-size: 18px;
    margin-bottom: 25px;
    margin-left: 30px;
}
/* 方块内部按钮的设置 */
.bluebutton {
    position: relative;
    width: 280px;
    height: 50px;
    margin: auto;
    margin-top: 152px;
    background-color: #2D2D2D;
    border-radius: 6px;
    animation:myfirst 2s;
	-webkit-animation:myfirst 2s;
}
@keyframes myfirst
{
	from {opacity: 0;}
	to {opacity:1;}
}

@-webkit-keyframes myfirst /* Safari and Chrome */
{
	from {opacity: 0;}
	to {opacity: 1;}
}
.bluebutton h3 {
    display: block;
    padding-top: 12px;
    font-size: 18px;
    font-family: W03;
}
.bluebutton img {
    position: absolute;
    left: 200px;
    top: 22px;
}
/* 小一点的方块 */
.bluesmall {
    float: left;
    width: 270px;
    height: 450px;
    margin-top: 168px;
    margin-right: 20px;
    /* background-image: url('@assets/渐变矩形.png'); */
    background-color: #ABB6DC; /* 浏览器不支持时显示 */
    background-image: linear-gradient(#F1F6FE, #ABB6DC);
    border-radius: 6px;
    transition:all 1s;
    -moz-transition:all 1s; /* Firefox 4 */
    -webkit-transition:all 1s; /* Safari and Chrome */
    -o-transition:all 1s; /* Opera */
}
.bluesmall h3 {
    font-size: 20px;
    color: #777777;
    margin-left: 30px;
    margin-top: 40px;
    margin-bottom: 30px;
}
.bluesmall p {
    font-size: 16px;
    color: #777777;
    margin-bottom: 20px;
    margin-left: 30px;
}
.bluesmall img{
    display: block;
   margin: auto;
}