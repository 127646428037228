
@font-face{
    font-family:'Noto Sans S Chinese';
    src : url('./font/NotoSansHans-Regular.otf')
}
@font-face{
    font-family:'W04';
    src : url('./font/站酷仓耳渔阳体-W04.ttf')
}
@font-face{
    font-family:'W03';
    src : url('./font/站酷仓耳渔阳体-W03.ttf')
}
h1 {
    font-family: W04;
}
h2 {
    font-family: w03;
}
p {
    font-size: Noto Sans S Chinese;
}
span {
        font-size: Noto Sans S Chinese;
}
a {
        font-size: Noto Sans S Chinese;
}
div {
    font-family:  Noto Sans S Chinese;
}
div .min {
    min-width: 1300px;
}
div .mmin {
    min-width: 300px;
}