div .scenarios{
    position: relative;
    width: 100%;
    height: 1080px;
    background-color: #171717;
}
.scenarios h1{
    display: block;
    color: white;
    text-align: center;
    padding-top: 100px;
    font-size: 35px;
    overflow: hidden;
}
.scenariosdiv {
    margin-top: 100px;
    height: 600px;
    width: 100%;
    background-color: black;
}
.scenariosdiv img{
    display: block;
    width:80px;
    margin: auto;
    margin-top: 200px;
}

.manyimg1{
    float: left;
    width: 52%;
    height: 600px;   
    /* filter: brightness(0.50);
    -webkit-filter: brightness(0.50);
    -o-filter: brightness(0.50);
    -moz-filter: brightness(0.50); */
    overflow:hidden;   
    background-size: cover ;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    background-image: url(@assets/背景图1.png);
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyimgg1{
    float: left;
    width: 12%;
    height: 600px;
    /* filter: brightness(0.50);
    -webkit-filter: brightness(0.50);
    -o-filter: brightness(0.50);
    -moz-filter: brightness(0.50); */
    overflow:hidden;   
    background-size: cover ;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    background-image: url(@assets/背景图1.png);
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyimg2{
    float: left;
    width: 12%;
    height: 600px;
    background-image: url(@assets/背景图2.png);
    background-size: cover ;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    /* filter: brightness(0.50);
    -webkit-filter: brightness(0.50);
    -o-filter: brightness(0.50);
    -moz-filter: brightness(0.50); */
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyimgg2{
    float: left;
    width: 52%;
    height: 600px;
    background-image: url(@assets/背景图2.png);
    background-size: cover ;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    /* filter: brightness(0.50);
    -webkit-filter: brightness(0.50);
    -o-filter: brightness(0.50);
    -moz-filter: brightness(0.50); */
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyimg3{
    float: left;
    width: 12%;
    height: 600px;
    background-image: url(@assets/背景图3.png);
    background-size: cover ;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    /* filter: brightness(0.50);
    -webkit-filter: brightness(0.50);
    -o-filter: brightness(0.50);
    -moz-filter: brightness(0.50); */
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyimgg3{
    float: left;
    width: 52%;
    height: 600px;
    background-image: url(@assets/背景图3.png);
    background-size: cover ;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    /* filter: brightness(0.50);
    -webkit-filter: brightness(0.50);
    -o-filter: brightness(0.50);
    -moz-filter: brightness(0.50); */
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyimg4{
    float: left;
    width: 12%;
    height: 600px;
    background-image: url(@assets/背景图4.png);
    background-size: cover ;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    /* filter: brightness(0.50);
    filter:alpha(brightness(0.50));
    -webkit-filter: brightness(0.50);
    -o-filter: brightness(0.50);
    -moz-filter: brightness(0.50); */
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyimgg4{
    float: left;
    width: 52%;
    height: 600px;
    background-image: url(@assets/背景图4.png);
    background-size: cover ;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    /* filter: brightness(0.50);
    filter:alpha(brightness(0.50));
    -webkit-filter: brightness(0.50);
    -o-filter: brightness(0.50);
    -moz-filter: brightness(0.50); */
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyimg5{
    float: left;
    width: 12%;
    height: 600px;
}
.manyboxall{
    position: absolute;
    width: 100%;
    height: 600px;
    overflow: hidden;
}
.manybox1{
    float: left;
    width: 52%;
    height: 600px;
    text-align: center;
    color: white;
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyboxg1{
    float: left;
    width: 12%;
    height: 600px;
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manybox2{
    float: left;
    width: 12%;
    height: 600px;
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyboxg2{
    float: left;
    width: 52%;
    height: 600px;
    text-align: center;
    color: white; 
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manybox3{
    float: left;
    width: 12%;
    height: 600px;
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyboxg3{
    float: left;
    width: 52%;
    height: 600px;
    text-align: center;
    color: white; 
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manybox4{
    float: left;
    width: 12%;
    height: 600px;
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyboxg4{
    float: left;
    width: 52%;
    height: 600px;
    text-align: center;
    color: white; 
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
.manyboxall p{
    margin-top: 50px;
    margin: auto;
    font-size: 14px;
    width: 70%;
    height: 63px;
    overflow:hidden;

}
.manyboxall h2 {
    color: white;
    margin-top: 120px;

}
.manyboxall h3 {
    margin: auto;
    margin-bottom: 30px;
    color: white;
    font-size: 16px;
    width: 70%;
    height: 20px;
    overflow:hidden;
}

.iconleft{
    position: relative;
    top: 50px;
    left: 0;
    display: block;
    margin: auto;
}

/* 提示样式 */
.Hloading {   
    margin: auto;
    width: 360px;
    height: 196px;
    background-color:#0C0C0C ;
    border-radius: 10px;
    opacity: 0.9;
    text-align: center;
}
.loadingspan {
    position: relative;
    top: 85px;
}
/* 按钮淡入淡出效果 */
.buttondc {
    animation:my1 2s;
	-webkit-animation:my1 2s;
}
@keyframes my1
{
	from {opacity: 0;}
	to {opacity:1;}
}

@-webkit-keyframes my1 /* Safari and Chrome */
{
	from {opacity: 0;}
	to {opacity: 1;}
}