div .FooterM {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 360pt;
    background-color: #2A2A2A;
    margin-top: 30pt;
    ul {
        width: 150pt;
        height: 140pt;
        list-style: none;
        font-size: 12pt;
        color: #FFFFFF;
        padding-top: 20pt;
    }
    li {
        margin-bottom: 29px;
        margin-left: -10pt;
    }
    // 设置手风琴标题
    .am-accordion .am-accordion-item .am-accordion-header {
        background-color: #2A2A2A;
        color: #FFFFFF;
        font-size: 12pt;
        padding-left: 20pt;
        margin-top: 40pt;
    }
    // 设置图标
    .am-accordion .am-accordion-item .am-accordion-header i {
        width: 18px;
        height: 18px;
        top: 14px;
    }
    // 消除下方灰线
    .am-accordion .am-accordion-item .am-accordion-header::after {
        height: 0;
    }
    // 消除上方灰线
    .am-accordion::before {
        height: 0;
    }
    // 下拉内容样式
    .am-list-item .am-list-line .am-list-content {
        color: #FFFFFF;
        font-size: 10pt;
        padding-left: 40pt;
    }
    .am-list-item {
        background-color: #2A2A2A;
        min-height: 33px;
    }
    // 下拉灰线去除
    .am-list-body div:not(:last-child) .am-list-line::after {
        height: 0;
    }
    .am-list-item:not(:last-child) .am-list-line::after {
        height: 0;
    }
    .am-list-body::after {
        height: 0;
    }
    .am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box::after {
        height: 0;
    }
    // 最底部的备案号
    .footerm {
        position: absolute;
        bottom: 10pt;
        width: 100%;
        height: 30pt;
        margin-bottom: 0;
        text-align: center;
        span {
            color: #959595;
            font-size: 8pt;
        }
    }
}