.enterprisebutton {
    margin-top: 5px;
    margin-bottom: 30px;
    margin-right: 2%;
    color: #4C7BE9;
    width: 18%;
    height: 50px;
}
.enterprisebutton p{
    margin-top: 10px;
}