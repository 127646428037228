.aboutmbody .CooperationMbox {
    position: relative;
    float: left;
    width: 30%;
    height: 40px;
    margin-top: 10px;
    border-radius: 1px;
    box-shadow: 0px 0px 5px rgba(#000000,0.08);
    p {
        padding-top: 10px;
        display: block;
        width: 66px;
        line-height: 20px;
        margin: auto;
        font-size: 8px;
        color: #2D2D2D;
        text-align: center;
    }
}

.CooperationM {
    width: 100%;
    height: 250px;
}