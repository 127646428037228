div .CasesM {
    position: relative;
    width: 100%;
    height: 196px;
    margin-top: 20px;
    img {
        width: 100%;
        // height: 196px;
        -webkit-filter: brightness(0.50);
        filter: brightness(0.50);
    }
    h1 {
        position: absolute;
        display: block;
        top: 50px;
        left: 20px;
        border-left:2px solid #2468F2;
        font-size: 20px;
        color: white;
        padding-left: 10px;
    }
    ul {
        position: absolute;
        list-style: none;
        top: 95px;
        left: -5px;
        li {
            float: left;
            font-size: 12px;
            color: white;
            margin-right: 10px;
        }
    }
    .casesmbutton {
        position: absolute;
        top: 150px;
        left: 235px;
        width: 80px;
        height: 26px;
        background-color: #2468F2;
    }
    span {
        display: block;
        font-size: 10px;
    }
}