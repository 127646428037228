.partner {
    width: 1220px;
    height: 2200px;
    margin: auto;
    /* background-color: grey; */
}

/* 面包屑样式 */
.partnerBread {
    margin-top: 30px;
    height: 30px;
    width: 100%;
    border-bottom: 2px solid #E4E4E4;
    /* background-color:green; */
}
.partnertitle {
    width: 100%;
    height: 100px;
    margin-top: 50px;
    /* background-color: hotpink; */
}
.partnertitle p{
    font-size: 12px;
    color: #C3C3C3;
}
.partnertitle h1{
    font-family: Noto Sans S Chinese;
}
.articles {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
}
.partnertext {
    width: 100%;
    border-bottom: 2px solid #E4E4E4;
}
.partnertext img{
    display: block;
    margin: auto;
}
.partnertext h3{
    font-weight: bold;
    margin: 30px auto;
}