div .AdvantagesM {
    float: left;
    width: 45%;
    height: 175px;
    // background-color: rgb(33, 146, 11);
    img {
        height: 36px;
    }
    h1 {
        margin-top: 10px;
        margin-bottom: 10px;
       font-size: 14px; 
    }
    p {
        font-size: 12px;
        width: 80%;
    }
}