.product {
    position: relative;
}
.producthead {
    width: 100%;
    height: 480px;
    background-image: url(@assets/产品技术.png);
    background-size:cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    /* -webkit-filter: brightness(0.50);filter: brightness(0.50); */
}
.productheadp {
    position: absolute;
    top: 220px;
    left:20%;
    color: white;
    font-size: 20px;
}
.producttitle{
    position: absolute;
    color: white;
    font-size: 50px;
    left: 20%;
    top: 122px;
}
.productbody {
    position: relative;
    width: 1220px;
    margin: auto;
}
.functiontitle {
    height: 110px;
    text-align: center;
}
.productbody h1{
    display: block;
    margin: auto;
    margin-top: 130px;
    font-size: 46px;
    color: #2D2D2D;
}