    // 顶部背景
    .productmback {
        position: relative;
        width: 100%;
        height: 220px;
        background-image: url('../../assets/手机产品技术.png');
        background-size: auto 220px;
        background-position: center;
        -webkit-filter: brightness(0.50);
        filter: brightness(0.50);
    } 
    // 背景图片的标题样式
    .productmbacktitle{
            position: absolute;
            font-size: 28px;
            color: #FFFFFF;
            top: 120px;
            left: 20px;
    }
    // 主体样式
    .productmbody {
        width: 100%;
        // height: 2000px;
        // background-color: skyblue;
        max-width: 340px;
        margin: auto;
        img {
            width: 100%;
            height: 186px;
        }
        .productmbodytitle {
            width: 100%;
            height: 20px;
            margin-top: 20px;
            // background-color: rgb(131, 128, 148);
            span {
            display: block;
            float: left;
            width: 5px;
            height: 10px;
            margin-top: 5px;
            margin-right: 2px;
            background-color: #2468F2;
            border-radius: 3px;
            }
             p {
            font-size: 14px;
            color: #2D2D2D;
             }
        }
        p{
            display: block;
            margin-left: 11px;
            width: 95%;
            font-size: 12px;
            color: #707070;
        }
    }