// 顶部背景
.caseshowsmback {
    position: relative;
    width: 100%;
    height: 220px;
    background-image: url('../../assets/案例展示banner.png');
    background-size: auto 220px;
    background-position: center;
    -webkit-filter: brightness(0.50);
    filter: brightness(0.50);
} 
// 背景图片的标题样式
.caseshowsmbacktitle{
        position: absolute;
        font-size: 28px;
        color: #FFFFFF;
        top: 120px;
        left: 20px;
}
// 主体样式
div .caseshowsmbody {
    width: 100%;
    // background-color: yellowgreen;
    margin: auto;
    max-width: 340px;
    margin-top: 47px;
}