
    // 顶部背景
.solutionmback {
    position: relative;
    width: 100%;
    height: 220px;
    background-image: url('../../assets/案例背景.png');
    background-size: auto 220px;
    background-position: center;
    -webkit-filter: brightness(0.50);
    filter: brightness(0.50);
} 
// 背景图片的标题样式
.solutionmbacktitle{
        position: absolute;
        font-size: 28px;
        color: #FFFFFF;
        top: 120px;
        left: 20px;
}
// 主体样式
.solutionmbody {
        width: 100%;
        // height: 2000px;
        margin: auto;
        max-width: 340px;
        // background-color: skyblue;
}
.solutionmtitle {
    width: 100%;
    height: 31px;
    margin-top: 50px;
    margin-bottom: 20px;
    // background-color: springgreen;
    border-left: 2px solid #2468F2;
    h1 {
        font-size: 20px;
        margin-left: 10px;
    }
    // 技术优势外框
}   
    .advantagesm{
    width: 100%;
    height: 350px;
    // background-color: blue;
    }