@import '~antd/dist/antd.css';
.solutioncasetext{
    position: relative;
    float: left;
    width: 40%;
    height: 400px;
    background-color: #224188;

}
@keyframes my1
{
	from {opacity: 0;}
	to {opacity:1;}
}

@-webkit-keyframes my1 /* Safari and Chrome */
{
	from {opacity: 0;}
	to {opacity: 1;}
}
.solutioncasetextp{
    float: left;
    margin-top: 80px;
    margin-left: 15%;
    width: 70%;
    text-align: justify;
}
.solutioncasetext p{
    color: white;
    font-size: 13px;
    animation:my1 1.5s;
	-webkit-animation:my1 1.5s;
}
.solutionbutton {
    position: absolute;
    left: 0%;
    top: 220px;
    width: 100px;
    animation:my1 1.5s;
	-webkit-animation:my1 1.5s;
}
.solutionbuttonfalse {
    margin-left: 10%;
    margin-top: 30px;
    width: 100px;
}
.solutionimgdivs { 
    width: 60%;
    height: 400px;
    overflow: hidden;
    background-color: #224188;
}
.solutionimgdiv { 
    width: 100%;
    height: 400px;
    overflow: hidden;
}
.solutionimg {
    margin: auto;
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
}
.solutionimg:hover {
    margin: auto;
    transform: scale(1.15);
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
}