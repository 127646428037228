@import '~antd/dist/antd.css';
div .starheader{
    position: relative;
    width: 100%;
    height: 115px;
}
div .iconcenter{
    width: 1200px;
    height: 150px;
    margin: auto;
    color: #2D2D2D;
    font-size: 22px;
    min-width: 720px;
}
.iconcenter h2{
    padding: 5px 40px;
    font-size: 18px;
    color: #2D2D2D;
    /* bottom: -10px; */
    font-family: Noto Sans S Chinese;
}
.icon1{
    width: 60px;
    height: 50px;
    margin: auto;
    background-image: url(@assets/icon1.png);
    background-size:cover;
}
.icon11{
    width: 60px;
    height: 50px;
    margin: auto;
    background-image: url(@assets/icon11.png);
    background-size:cover;
}
.icon2{
    width: 60px;
    height: 50px;
    margin: auto;
    background-image: url(@assets/icon2.png);
    background-size:cover;
}
.icon22{
    width: 60px;
    height: 50px;
    margin: auto;
    background-image: url(@assets/icon22.png);
    background-size:cover;
}
.icon3{
    width: 43px;
    height: 50px;
    margin: auto;
    background-image: url(@assets/icon3.png);
    background-size:60px 51px;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
}
.icon33{
    width: 43px;
    height: 50px;
    margin: auto;
    background-image: url(@assets/icon33.png);
    background-size:60px 51px;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
}
.icon4{
    width: 58.25px;
    height: 60px;
    margin: auto;
    background-image: url(@assets/icon4.png);
    background-size:58.25px 60px;
}
.icon44{
    width: 58.25px;
    height: 60px;
    margin: auto;
    background-image: url(@assets/icon44.png);
    background-size:58.25px 60px;
}
.iconcenter h2{
    margin-top: 10px;
}
div .starfooter{
    position: relative;
    top: 10px;
    width: 100%;
    height:450px; 
}

/* 文档块区域 */
div .startext{
    width: 1220px;
    height: 450px;
    margin: auto;
    background-color: #FFFFFF;
    border-radius: 5px;
    border-color: white;
    box-shadow: 1px 1px 5px #888888;
}
.startext h2{
    position: relative;
    top: 60px;
    left: 50px;
}
.floatleft1{
    position: relative;
    top: 80px;
    left: 30px;
}
.startext ul li{
    margin-top: 15px;
}
.floatleft2 li{position: relative;
    top: 70px;
    left: 10px;
    float: left;
    list-style-type:none;
    background-color: #F2F3F7;
    padding:2px 15px;
    font-size: 12px;
    color: #4C7BE9;
    margin-right:20px;
    border-radius: 3px;
}



.fade-enter {
    opacity: 0;
    z-index: 1;
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }

