.culturemimg {
    width: 100%;
    height: 174px;
    overflow: hidden;
    margin-bottom: 20px;
    img {
        display: block;
        margin-top: -100px;
    }

}

.culture {
    text-align: center;
    h1 {
        font-size: 14px;
    }
    p {
        display: inline;
        font-size: 12px;
    }
}