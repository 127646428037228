div .CaseM {
    position: relative;
    width: 100%;
    height: 500px;
    // background-color: burlywood;
    .CaseMleft {
        width: 26.5%;
        height: 200px;
        ul {
            list-style: none;
            .caseli {
                width: 180%;
                height: 50px;
                margin-left: -40px;
                // background-color: chocolate;
                border-left: 3px solid #4C7BE9;
            }
            .caselihover {
                width: 180%;
                height: 50px;
                margin-left: -40px;
                padding-top: 10px;
                border-left: 3px solid #959595;
            }
            .casep {
                display: block;
                font-size: 12px;
                color: #4C7BE9;
                margin-left: 10px;
                padding-top: 5px;
                // line-height: 25px;
            }
            .casephover {
                font-size: 10px;
                color: #959595;
                margin-left: 10px;
                // line-height: 25px;
            }
        }
    }
    .CaseMright {
        position: absolute;
        top: 0px;
        left: 110px;
        width: 65%;
        height: 370px;
        background-color:#4C7BE9;
        border-radius: 6px;
        img {
            width: 90%;
            display: block;
            margin: auto;
            margin-top: 10px;
        }
        p {
            display: block;
            margin: auto;
            margin-top: 10px;
            width: 90%;
            color: white;
            font-size: 11px;
            text-align: justify;
        }
    }
    .CaseMbutton {
        position: absolute;
        top: 310px;
        left:50%;
    }
}