.cultrueboxbig {
    width: 80%;
    height: 130px;
    /* background-color: steelblue; */
    transition:height 0.5s; 
    -moz-transition:height 0.5s; /* Firefox 4 */
    -webkit-transition:height 0.5s; /* Safari and Chrome */
    -o-transition:height 0.5s; /* Opera */
}
div .cultrueheaderbig {
    height: 50px;
    width: 100%;
    /* background-color: green; */
    border-right: 3px solid #4C7BE9;
    font-size: 30px;
    font-weight: bold;
    transition:height 0.5s;
    -moz-transition:height 0.5s; /* Firefox 4 */
    -webkit-transition:height 0.5s; /* Safari and Chrome */
    -o-transition:height 0.5s; /* Opera */
}
.cultruebox{
    width: 80%;
    height: 90px;
    /* background-color: greenyellow; */
    transition:height 0.5s;
    -moz-transition:height 0.5s; /* Firefox 4 */
    -webkit-transition:height 0.5s; /* Safari and Chrome */
    -o-transition:height 0.5s; /* Opera */
}
.cultrueheader {
    width: 100%;
    height: 40px;
    /* background-color: grey; */
    border-right: 3px solid #B8B8B8;
    font-size: 25px;
    transition:height 0.5s; 
    -moz-transition:height 0.5s;/* Firefox 4 */
    -webkit-transition:height 0.5s; /* Safari and Chrome */
    -o-transition:height 0.5s; /* Opera */
}
.cultrueboxbig ul{
    list-style: none;
    font-size: 13px;
    color: #2D2D2D;
}
.cultruebox ul{
    list-style: none;
    font-size: 13px;
    color: #2D2D2D;
}