div .threebox {
    position: relative;
    width: 98%;
    height: 110px;
    background-color: #F2F7FF;
    border-radius: 6px;
    margin: auto;
    img {
        position: absolute;
        width: 15%;
        top:30px;
        left: 30px;
    }
    .threetxt {
        width: 66%;
        height: 110px;
        margin-left: 33%;
        margin-top: 15px;
        border: 1px solid #F2F7FF;
        h4 {
            font-size: 14px;
            margin-top: 10px;
        }
        ul {
            display: block;
            float: left;
            list-style: none;
            li {
                font-size: 11px;
                margin-left: -40px;
                color: #787878;
            }
        }
    }
}