.shows{
    position: relative;
}
.showsimg {
    height: 480px;
    width: 100%; 
    background-image: url('@assets/案例展示banner.png');
}
/* .showsblack{
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    height: 480px;
    width: 100%;
    opacity: 0.5;
} */
/* 大标题 */
div .showstitle {
    position: absolute;
    font-size: 25px;
    top: 122px;
    left: 20%;
}
/* 中心部分 */
.showsbody {
    margin: auto;
    margin-top: 200px;
    width: 1220px;
}