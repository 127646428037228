div .startext{
    position: relative;
    width: 60%;
    height: 450px;
    margin: auto;
    background-color: #FFFFFF;
    border-radius: 5px;
    border-color: white;
    box-shadow: 1px 1px 5px #888888;
}
@keyframes my1
{
	from {opacity: 0;}
	to {opacity:1;}
}

@-webkit-keyframes my1 /* Safari and Chrome */
{
	from {opacity: 0;}
	to {opacity: 1;}
}
.startext h2{
    position: relative;
    top: 60px;
    left: 50px;
}
.floatleft11{
    position: relative;
    top: 80px;
    left: 30px;
}
.startext ul li{
    margin-top: 15px;
}
.floatleft22 {
    position: absolute;
    top: 200px;
    width: 500px;
    height: 50px;

}
.floatleft22 li{
    position: relative;
    left: 10px;
    top: 50px;
    float: left;
    list-style-type:none;
    background-color: #F2F3F7;
    padding:2px 15px;
    font-size: 12px;
    color: #4C7BE9;
    margin-right:20px;
    border-radius: 3px;
}
.starimg {
    float: left;
    position: relative;
    top: 20px;
    left: 5%;
    width: 53%;
    height: 350px;
}
.startextul {
    float: left;
    width: 40%;
    height: 350px;
    animation:my1 1s;
	-webkit-animation:my1 1s;
}
.startext img{
    position: relative;
    width: 100%;
    animation:my1 1s;
	-webkit-animation:my1 1s;
}