div .HeaderM .Jjfa {
    // 设置手风琴标题
    .am-accordion .am-accordion-item .am-accordion-header {
        background-color:#FFFFFF ;
        color: #2468F2;
        font-size: 16px;
        padding-left: 20px;
    }
        // 设置无体积
        .am-accordion .am-accordion-item .am-accordion-content {
            position: relative;
            width: 100%;
            height: 110px;
            z-index: 5;
        }
    // 设置图标
    .am-accordion .am-accordion-item .am-accordion-header i {
        width: 18px;
        height: 18px;
        top: 14px;
    }
    // 消除下方灰线
    .am-accordion .am-accordion-item .am-accordion-header::after {
        height: 0;
    }
    // 消除上方灰线
    .am-accordion::before {
        height: 0;
    }
    // 下拉内容样式
    .am-list-item .am-list-line .am-list-content {
        color: #2468F2;
        font-size: 10px;
        text-align: center;
    }
    .am-list-item {
        min-height: 33px;
        margin-bottom: 0;
        font-weight: normal;
    }
    // 下拉灰线去除
    .am-list-body div:not(:last-child) .am-list-line::after {
        height: 0;
    }
    .am-list-item:not(:last-child) .am-list-line::after {
        height: 0;
    }
    .am-list-body::after {
        height: 0;
    }
    .am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box::after {
        height: 0;
    }
    .am-accordion .am-accordion-item .am-accordion-header {
        height: 44px;
        width: auto;
    }
    
}