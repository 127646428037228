.itemimgs {
    display: block;
    margin: auto;
    width: 380px;
    /* height: 268.72px; */
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
}
.itemimgs:hover {
    display: block;
    margin: auto;
    width: 380px;
    height: auto;
    transform: scale(1.2); 

}
.aboutimg {
    float: left;
    width: 380px;
    height: 268.72px; 
    margin-right: 40px;
    margin-top: 60px;
    overflow: hidden;
}
.aboutimgbig {
    width: 120%;
    margin-left: -10%;
}