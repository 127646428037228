.swiper-container {
  width: 1220px;
  height: 100%;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slideimg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dynamic {
    width: 100%;
    height: 500px;

}
div .dyimg {
    width: 100%;
    height: 200px;
    margin: 0px auto;
}
div .dyexternal{
    width: 100%;
    height: 500px;
    overflow:hidden;
}
.dyexternal p{
    font-size: 13px;
}
.dyexternal h2{
    font-size: 22px;
}
div .dynamicimg {
    width: 130%;
    height: 250px;
    margin-left: -15%;
    margin-bottom: 20px;
    overflow:hidden;
    background-color: red;
}
.dynamic img{
    display: block;
    margin: auto;
    width: 77%;
    height: auto;
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
}
.dynamic img:hover{
    margin: auto;
    transform: scale(1.15)
}
div .slide{
    margin: auto;
    width: 80%;
    height: 200px;
}
.day {
    font-size: 10px;
    color: #959595;
}