/* 应用场景css */
div .Application{
    position: relative;
    width:1220px;
    height: 900px;
    margin: auto;
    margin-top: 100px;
}
div .Applications{
    position: relative;
    width:1220px;
    height: 950px;
    margin: auto;
    margin-top: 100px;
}
.ant-carousel .slick-dots li button{
    background: #959595;
    height: 5px;
}
.Applications li button{
    background: #4C7BE9;
    
}
.ant-carousel .slick-dots li.slick-active button{
    background:#4C7BE9;
}
div .Applicationyy{
    position: relative;
    width:1220px;
    height: 800px;
    margin: auto;
    margin-top: 100px;
}
div .Applicationtitle{
    width: 100%;
    height: 50px;
    border-left: 3px solid #4C7BE9;
}
.Applicationtitle h2{
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    margin-left: 30px;
    margin-top: 2px;
}
.scenarios1{
    position: absolute;
    width: 580px;
    margin-top: 20px;
    height: 300px;
    background-image: url(@assets/汽车.png);
    background-size: cover;
    background-position: center;
    /* -webkit-filter: brightness(0.50);
    filter: brightness(0.50); */
}
.scenarios2{
    position: absolute;
    margin-top: 20px;
    left: 640px;
    width: 580px;
    height: 300px;
    background-image: url(@assets/高校.png);
    background-size: cover;
    background-position: center;
    /* -webkit-filter: brightness(0.50);
    filter: brightness(0.50); */
}
.scenarios3{
    position: absolute;
    top: 380px;
    width: 580px;
    height: 300px;
    background-image: url(@assets/安防.png);
    background-size: cover;
    background-position: center;
    /* -webkit-filter: brightness(0.50);
    filter: brightness(0.50); */
}
.scenarios4{
    position: absolute;
    left: 640px;
    top: 380px;
    width: 580px;
    height: 300px;
    background-image: url(@assets/景区.png);
    background-size: cover;
    background-position: center;
    /* -webkit-filter: brightness(0.50);
    filter: brightness(0.50); */
}
/* 蓝色背景 */
.scenarios11{
    position: absolute;
    width: 580px;
    float: left;
    margin-top: 20px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0;
}
.scenarios22{
    position: absolute;
    width: 580px;
    left: 640px;
    float: left;
    margin-top: 20px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0;
}
.scenarios33{
    position: absolute;
    width: 580px;
    top: 380px;
    float: left;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0;
}
.scenarios44{
    position: absolute;
    width: 580px;
    top: 380px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0;
    left: 640px;
}
.scenarios11:hover{
    position: absolute;
    width: 580px;
    float: left;
    margin-top: 20px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0.5;
}
.scenarios22:hover{
    position: absolute;
    width: 580px;
    left: 640px;
    float: left;
    margin-top: 20px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0.5;
}
.scenarios33:hover{
    position: absolute;
    width: 580px;
    top: 380px;
    float: left;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0.5;
}
.scenarios44:hover{
    position: absolute;
    width: 580px;
    top: 380px;
    height: 300px;
    background-color: #4C7BE9;
    opacity: 0.5;
    left: 640px;
}
/* 背景下个小标签的样式 */
.wisdom{
    position: absolute;
    top: 100px;
    left: 19%;
    font-size: 30px;
    color: white;
}
.Colleges {
    position: absolute;
    top: 100px;
    left: 71%;
    font-size: 30px;
    color: white;
}
.security {
    position: absolute;
    top: 480px;
    left: 19%;
    font-size: 30px;
    color: white;
}
.spot {
    position: absolute;
    top: 480px;
    left: 71%;
    font-size: 30px;
    color: white;
}
.wisdomp{
    position: absolute;
    top: 170px;
    left: 6.5%;
    width: 35%;
    font-size: 16px;
    color: white;
    text-align: center;
}
.Collegesp {
    position: absolute;
    top: 170px;
    left: 59%;
    width: 35%;
    font-size: 16px;
    color: white;
    text-align: center;
}
.securityp {
    position: absolute;
    top: 550px;
    left: 6.5%;
    width: 35%;
    font-size: 16px;
    color: white;
    text-align: center;
}
.spotp {
    position: absolute;
    top: 550px;
    left: 59%;
    width: 35%;
    font-size: 16px;
    color: white;
    text-align: center;
}