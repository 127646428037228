div .HomeM {
    width: 100%;
    .threetitle {
    text-align: center;
    h2 {
        font-size: 20px;
        color: #2D2D2D;
        margin-top: 50px;
        font-weight: bold;
    }
    p {
        font-size: 12px;
        color: #2D2D2D;
        margin-bottom: 3px;
    }
    }
    .shufflingall {
        width: 100%;
    }
    .Mbody {
        width: 100%;
        // background-color: slateblue;
        margin: auto;
        max-width: 340px;
    }
}