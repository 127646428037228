@import '~antd/dist/antd.css';
div .engine{
    position: relative;
    width: 100%;
    height: 900px;
    background-image: url(../../assets/三维背景.png);
    background-size: cover ;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
}
div .engineTitle{
    width: 100%;
    height: 100px;
    margin-top: 160px;
    text-align: center;
    font-family: HuXiaoBo-NanShen;
}
.engineTitle h1{
    font-size: 35px;
}
div .architecture {
    position: relative;
    width: 1220px;
    height: 350px;
    margin: auto;
    top: 50px;
    /* overflow: hidden; */
}
div .architecturebig{
    float: left;
    width: 395px;
    height: 350px;
    background-color: #4C7BE9;
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
/* 框架中的文字css
div .engineh3{
    position: relative;
    top: 40px;
    width: 100%;
    height: 50px;
    text-align: center;
}
.engineh3 h2{
    color: white;
}

div .engineh4{
    position: relative;
    top: 40px;
    width: 100%;
    height: 50px;
}
div .engineh4 ul{
    display: block;
    float: left;
    margin-top: 15px;
    padding-left: 23%;
    color: white;
    font-size: 12px;
}
div .engineh5{
    position: relative;
    top: 85px;
    width: 100%;
    height: 50px;
}
div .engineh6{
    position: relative;
    top: 90px;
    width: 100%;
    height: 50px;
} */
/* 改变后的样式 */
div .box {
    float: left;
    width: 220px;
    height: 400px;
    background-color: #F2F7FF;
    border-left: 1px solid rgba(29, 51, 101, 0.22);
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */

}

div .box1{
    position: relative;
    top: 93px;
    width: 100%;
    height: 50px;
    text-align: center;
    overflow: hidden;
}

div .box2{
    /* position: relative;
    height: 100px; */
    float: left;
    width: 50%;
    height: 50px;
    margin-top: 100px;
    padding-left: 3%;
    color: #959595;
    font-size: 12px;
    transition:padding 1s;
    -moz-transition:padding 1s; /* Firefox 4 */
    -webkit-transition:padding 1s; /* Safari and Chrome */
    -o-transition:padding 1s; /* Opera */
}
div .Box2{
    float: left;
    width: 50%;
    height: 50px;   
    margin-top: 100px;
    padding-left: 3%;
    color: #959595;
    font-size: 12px;
    transition:padding 1s;
    -moz-transition:padding 1s; /* Firefox 4 */
    -webkit-transition:padding 1s; /* Safari and Chrome */
    -o-transition:padding 1s; /* Opera */
}

div .box3{
    position: relative;
    top: 85px;
    width: 100%;
    height: 50px;
}
/* 数字场景精度 */
div .boxg{
    float: left;
    width: 340px;
    height: 400px;
    background-color: #4C7BE9;
    transition:width 1s;
    -moz-transition:width 1s; /* Firefox 4 */
    -webkit-transition:width 1s; /* Safari and Chrome */
    -o-transition:width 1s; /* Opera */
}
div .boxg1{
    position: relative;
    top: 93px;
    width: 100%;
    height: 50px;
    text-align: center;
    overflow: hidden;
}
.boxg1 h2{
    color: white;
    font-size: 23px;
}

div .boxg2 {
    float: left;
    margin-top: 100px;
    width:50%;
    height: 60px;
    padding-left: 15%;
    padding-right:-30%;
    color: #FFFFFF;
    font-size: 12px;
    transition:padding 1s;
    -moz-transition:padding 1s; /* Firefox 4 */
    -webkit-transition:padding 1s; /* Safari and Chrome */
    -o-transition:padding 1s; /* Opera */
}
div .Boxg2 {
    float: left;
    margin-top: 100px;
    width: 50%;
    height: 60px;
    padding-left: 5%;
    color: #FFFFFF;
    font-size: 12px;
    transition:padding 1s;
    -moz-transition:padding 1s; /* Firefox 4 */
    -webkit-transition:padding 1s; /* Safari and Chrome */
    -o-transition:padding 1s; /* Opera */
}
/* 平台宗旨 */
div .box8 {
    float: left;
    width: 240px;
    height: 50px;
    margin-left: 10%;
    margin-top: 100px;
    color: #959595;
    font-size: 12px;
}
div .box9 {
    float: left;
    width: 240px;
    height: 50px;
    margin-left: 26%;
    margin-top: 100px;
    color: #FFFFFF;
    font-size: 12px;
}
.box8 p{
    margin-bottom: 7px;
}
div .boxg3{
    position: relative;
    top: 85px;
    width: 100%;
    height: 50px;
}
div .boxg3{
    position: relative;
    top: 90px;
    width: 100%;
    height: 50px;
}
/* 改变样式结束 */
div .img1{
    width: 40px;
    height: 40px;
    background-image: url(../../assets/组2.png);
    margin:auto;
    margin-top: 130px;
    background-size:cover;
}
div .imgg1{
    width: 50px;
    height: 50px;
    background-image: url(../../assets/组2改.png);
    margin:auto;
    margin-top: 130px;
    background-size:cover;
}
div .img2{
    width: 40px;
    height: 40px;
    background-image: url(../../assets/组3.png);
    margin:auto;
    margin-top: 130px;
    background-size:cover;
}
div .imgg2{
    width: 50px;
    height: 50px;
    background-image: url(../../assets/组3改.png);
    margin:auto;
    margin-top: 130px;
    background-size:cover;
}
div .img3{
    width: 42px;
    height: 40px;
    background-image: url(../../assets/组4.png);
    margin:auto;
    margin-top: 130px;
    background-size:cover;
}
div .imgg3{
    width: 50px;
    height: 50px;
    background-image: url(../../assets/组4改.png);
    margin:auto;
    margin-top: 130px;
    background-size:cover;
}
div .img4{
    width: 44.5px;
    height: 40px;
    background-image: url(../../assets/组5.png);
    margin:auto;
    margin-top: 130px;
    background-size:cover;
}
div .imgg4{
    width: 50px;
    height: 50px;
    background-image: url(../../assets/组5改.png);
    margin:auto;
    margin-top: 130px;
    background-size:cover;
}
div .img5{
    width: 44.5px;
    height: 40px;
    background-image: url(@assets/组6.png);
    margin:auto;
    margin-top: 130px;
    background-size:cover;
}
div .imgg5{
    width: 50px;
    height: 50px;
    background-image: url(@assets/组6改.png);
    margin:auto;
    margin-top: 130px;
    background-size:cover;
}
/* 样式改变结束 */
/* 明星案例 */
div .fttitle {
    position: absolute;
    top: 700px;
    width: 100%;
    height: 70px;
    font-size: 20px;
    text-align: center;
}
div .star {
    width: 100%;
    height: 750px;
}
/* 底部文字 */

.scenariosimgs h1{
    margin: 30px 0;
}
.scenariosimgs h2{
    color: white;
    margin-top: 60px;
}
.scenariosimgs h3{
    color: white;
    margin-top: 20px;
    margin-bottom: 50px;
}
.iconleft {
    margin-top: 200px;
}
.dynamic1{
    margin-top:100px ;
    font-size: 45px;
    text-align: center;
}
.qydt {
    font-size: 35px;
    text-align: center;
    margin-top:80px ;
}


/* .architecture li{
    width: 84px;
} */


/* 视频宽度 */
.xuanyan {
    position: relative;
    width: 100%;
    height: 640px;
    overflow: hidden;
}
.xuanyan1 {
    position: relative;
    width: 100%;
    height: 640px;
    overflow: hidden;
    background-image: url('../../assets/轮播图2.png');
}
.xuanyan2 {
    position: relative;
    width: 100%;
    height: 640px;
    overflow: hidden;
    background-image: url('../../assets/轮播图3.png');
}
.xuanyan img{
    width: 100%;
}
.homevideo {
    margin-top: -150px;
}

.over {
    width: 100%;
    height: 25px;
    overflow: hidden;
}

/* 轮播图按钮样式 */
.lbbutton {
    position: absolute;
    top: 340px;
    left: 355px;
    width: 120px;
    height: 40px;
}
.lbbutton span{
    font-size: 16px;
}