div .ManyM {
    width: 100%;
    height: 560px;
    // background-color: yellowgreen;
    text-align: center;
    h1 {
        font-size: 20px;
        padding-top: 30px;
    }
    .manymbox {
        width:100%;
        height: 460px;
        margin: auto;
        overflow: hidden;
        // background-color: skyblue;
    }
}