.Function {
    position: relative;
    width: 100%;
    height: 550px;
}
.Functionleft {
    float: left;
    width: 510px;
    height: 420px;
}
.functiontxt {
    margin: auto;
    margin-top: 70px;
    width: 469px;
}
.Functionleft h2{
    font-size: 26px;
    font-family: Noto Sans S Chinese;
}
.blueline {
    width: 100px;
    height: 3px;
    background-color: #2468F2;
    margin-bottom: 60px;
}
.Functionleft p{
    color: #707070;
    font-size: 16px;
    font-family: Noto Sans S Chinese;
}
.Functionright {
    float: left;
    width: 710px;
    height: 420px;
}