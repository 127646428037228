div .footer{
    position: relative;
    margin-top: 150px;
    background-color: #171717;
    width: 100%;
    height: 478px;
}
div .logoblue{
    margin-left: 880px;
    margin-top: 100px;
    width: 240px;
    height: 40px;
    background-image: url(../../../assets/logoblue.png);
    background-size: 230px;
    background-repeat: no-repeat;
}
/* table的样式 */
div .ftable {
    margin: auto;
    padding-top: 100px;
    width: 1220px;
    height: 478px;
    color: white;
}
div .ftable ul{
    float: left;
    list-style: none;
    margin-left: -30px; 
    margin-right: 80px;
}
div .ftable li{
    padding: 8px;
    font-size: 16px;

}
.ftable a{
    color:white ;
}
/* 最下部样式 */
div .bottom {
    font-size: 16px;
    position: absolute;
    bottom: 30px;
    color: white;
    height: 20px;
}
div .bottom1 {
    float: left;
    height: 20px;
}
div .bottom2 {
    float: left;
    margin-left: 280px;
    height: 20px;
}
div .bottom3 {
    float: left;
    margin-left:240px;
    height: 20px;
}
div .bottom4 {
    float: left;
    margin-left: 100px;
    height: 20px;
}
