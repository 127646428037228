.StyleM{
    .imgm1 {
        width: 60% !important;
        //  padding-left: -20%;
        //  padding-right: -20%;
        height: 200px;
        background-color: blue;
    }   
    img {
    height: 150px;
    }
    .stylemimg1 {
          img {
              height: 200px;
          }
    }
    .swiper {
        width: 100%;
        height: 100%;
      }
      
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }
    //   .swiper-slide {
    //     width: 60%;
    //   }
}

