.Square {
    width: 1220px;
    height: 430px;
    margin-top: 40px;
    -webkit-box-shadow: #dbcbcb 0px 0px 15px;
    -moz-box-shadow: #dbcbcb 0px 0px 15px;
    box-shadow: #dbcbcb 0px 0px 15px;
}
.Squareimg {
    float: left;
    width: 700px;
    height: 430px;
}
.Squareimg img{
    border-radius: 4px;
}
.Squareimgsmalls{
    position: absolute;
    top: 33px;
    left: 485px;
}
.Squaretextg {
    float: left;
    width: 520px;
    height: 430px;
    text-align: center;
    background-color:#F6F8FB ;
}
.Squaretextg h1{
    margin: auto;
    margin-top: 120px;
    font-family: Noto Sans S Chinese;
    font-size: 26px;
    font-weight: bold;
}
.Squaretextg p{
    margin-top: 40px;
    color: #787878;
    font-size: 16px;
}
.showsbutton {
    margin-top: 120px;
    margin-left: 260px;
    background-color: #2468F2;
}