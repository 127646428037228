@import '~antd/dist/antd.css';
div .header{
    position: relative;
    width: 100%;
    height:60px;
    background-color: #171717;
}
div .logo{
    position: absolute;
    left: 2%;
    top: 20px;
    background-size:150px;
    height: 23px;
    width: 150px;
    background-image: url(../../../assets/logo.png);
    background-repeat: no-repeat;
}
div .navigation {
    position: absolute;
    top: 18px;
    left: 15%;
    font-size: 11px;
    height: 23px;
    width: 50%;
}
div .menu {
    font-size: 16px;
    padding: 0 18px;
    float: left;
    text-align: center;
    height: 30px;
}
div .menuhover{
    font-size: 16px;
    padding: 0 18px;
    float: left;
    text-align: center;
    height: 30px;
    border-bottom: 2px solid white;
}
div .menu:hover{
    font-size: 16px;
    text-align: center;
    color: white;
    height: 30px;
    border-bottom: 2px solid white;
}

span{
    color: white;
    font-size: 14px;
}

div .cooperation {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 84px;
    height: 30px;
    background-color: #4C7BE9;
    color: white;
    text-align: center;
    border-radius: 3px;
}
.menu a{
    display: block;
    color: white;
}

.menublack {
    width: 130px;
    height: 120px;
    margin-top: 8px;
    background-color: #FFFFFF;
}
.menublacks:hover {
    background-color: #4C7BE9;
}
.menublacks {
    width: 130px;
    height: 55px;
    text-align: center;
}
.menublacks a{
    color: #010E2D;
    font-size: 12px;

}